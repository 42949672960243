<template>
  <div class="w-full font-poppins">
    <div class="my-4 min-w-full flex justify-between">
      <h1 class="text-2xl md:text-3xl">Cart</h1>
      <div class="text-right">
        <p class="text-sm text-gray-500">{{ cartTotalLength }} items</p>
        <strong class="text-2xl">{{ cartTotalPrice.toFixed(2) }} €</strong>
      </div>
    </div>

    <div class="min-w-max flex flex-col my-4" v-if="cartTotalLength">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 hidden md:block">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Product
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Color
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Size
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Prize
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Delete</span>
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <CartItem v-for="item in cart.items" :key="item.product.id" :initialItem="item" :cart="cart"/>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
          v-for="item in cart.items" :key="item.product.id"
          class="grid grid-cols-2 text-sm p-2 border-b block md:hidden text-gray-500 max-w-full">
        <div>
          <div>
            {{ item.product.name }}
          </div>
          <div class="text-xs">
            {{ item.product.price }} € <span class="text-xs">x</span> {{ item.quantity }}
          </div>
        </div>
        <div class="text-right">
          <div>
            {{ item.specs.name }} - {{ item.size.size || "No size" }}
          </div>
          <div class="text-xs">
            {{ getItemTotal(item).toFixed(2) }} €
          </div>
        </div>
      </div>
    </div>

    <p v-else>You don't have any products in your cart...</p>

    <div v-if="cartTotalLength" class="w-full flex flex-col items-end">
      <div class="flex md:w-1/2">
        <input type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-l-md w-1/2" v-model="discountCode" placeholder="Enter your discount code"/>
        <a @click="checkDiscount" class="w-1/2 bg-indigo-600 border border-transparent rounded-r-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Apply Code</a>
      </div>
      <router-link type="button" to="/cart/checkout" class="items-center my-2 w-full text-center md:w-60 px-4 py-2 w-auto border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Checkout
      </router-link>
    </div>
  </div>
</template>

<script>
    import axios from 'axios';
    import CartItem from '../components/CartItem'
    import {createToast} from "mosha-vue-toastify";
    export default {
        name: "Cart",
        components: {
            CartItem
        },
        data() {
            return {
                cart: {
                    items: []
                },
              discountCode: ''
            }
        },
        mounted() {
            this.cart = this.$store.state.cart
        },
        methods: {
          removeFromCart(item) {
            this.cart.items = this.cart.items.filter(i => i.product.id !== item.product.id)
          },
          getItemTotal(item) {
            if (item.discount_price) {
              return item.quantity * item.discount_price
            } else {
              return item.quantity * item.product.price
            }
          },
          async checkDiscount() {
            await axios
                .get(`/api/v1/discount-code/${this.discountCode}`)
                .then(response => {
                  const discount = response.data
                  this.$store.commit('applyDiscountToCart', discount)
                  createToast({
                        title: 'Success',
                        description: `Code ${discount.code} applied!`
                      },
                      {
                        position: 'top-right',
                        type: 'success',
                        transition: 'bounce'
                      })
                })
                .catch(error => {
                  createToast({
                        title: 'Something went wrong',
                        description: 'This code does not exist...'
                      },
                      {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'bounce'
                      })
                  console.log(error)
                })
          }
        },
        computed: {
            cartTotalLength() {
                return this.cart.items.reduce((acc, curVal) => {
                    return acc += curVal.quantity
                }, 0)
            },
            cartTotalPrice() {
                return this.cart.items.reduce((acc, curVal) => {
                  if (curVal.product.discountPrice){
                    return acc += curVal.product.discountPrice * curVal.quantity
                  } else {
                    return acc += curVal.product.price * curVal.quantity
                  }
                }, 0)
            },
        }
    }
</script>

<style scoped>

</style>
